//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppFeatures from '@/components/AppFeatures.vue';
import AppPricing from '@/components/AppPricing.vue';
import CallToAction from '@/components/elements/CallToAction.vue';

export default {
  layout: 'hero',
  components: { AppFeatures, AppPricing, CallToAction },
  computed: {
    showSenjaCarousel() {
      return process.env.SENJA_HERO_CAROUSEL === 'yes';
    }
  }
};
